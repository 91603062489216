import gql from 'graphql-tag'
import { omit } from 'underscore'
import { ApolloQueryResult } from 'apollo-client'

export type VideoCommand =
  | {
      type: 'create'
      payload: {
        videoId?: string
        trackId: string
        format: string
        type: string
        language: string
        name: string
        authorEmail: string
        infClassification?: string
      }
    }
  | {
      type: 'updateGeneral'
      accessTaskId?: string
      payload: {
        videoId: string
        version?: number
        trackId?: string
        format?: string
        type?: string
        videoHumanId?: any
        name?: string
        abstract?: string
        description?: string
        source?: string
        thumbnail?: string
        quickGuide?: string
        authorEmail?: string
        infClassification?: string
        uploadOptions?: {
          autoTranscribe?: boolean
          autoCopyToManuscript?: boolean
          autoCopyManuscriptToDescription?: boolean
        }
      }
    }
  | {
      type: 'updateTranslation'
      accessTaskId?: string
      payload: {
        videoId: string
        language: string
        version: number
        name?: string
        abstract?: string
        description?: string
        source?: string
        voiceOver?: string
      }
    }
  | {
      type: 'updateVideoStatus'
      payload: {
        videoId: string
        version: number
        language: string
        status: 'draft' | 'readyToPublish' | 'published' | 'archived'
      }
    }
  | {
      type: 'addTranslationVideo'
      payload: {
        videoId: string
        language: string
        version: number
      }
    }
  | {
      type: 'deleteTranslationVideo'
      payload: {
        videoId: string
        language: string
        version: number
      }
    }
  | {
      type: 'publishTranslation'
      payload: {
        videoId: string
        language: string
        version: number
      }
    }
  | {
      type: 'unpublishTranslation'
      payload: {
        videoId: string
        language: string
        version: number
      }
    }
  | {
      type: 'createMajorVersion'
      payload: {
        videoId: string
        language?: string
        reuseVersionSource?: number
        reuseVideo?: boolean
        reuseRenderDefinition?: boolean
        keepTranslations?: string[]
      }
    }
  | {
      type: 'defineActiveVersion'
      payload: {
        videoId: string
        version: number
        shouldRetakeMandatoryTraining?: boolean
      }
    }
  | {
      type: 'removeActiveVersion'
      payload: {
        videoId: string
      }
    }
  | {
      type: 'addVideoFile'
      accessTaskId?: string
      payload: {
        videoId: string
        language: string
        version: number
        fileId: string
        s3Path: string
        fileType?: string
        title?: string
        description?: string
      }
    }
  | {
      type: 'removeVideoFile'
      accessTaskId?: string
      payload: {
        videoId: string
        language: string
        version: number
        fileId: string
      }
    }
  | {
      type: 'updateVideoVoiceOverBreakpoints'
      payload: {
        videoId: string
        language: string
        version: number
        breakpoints: number[]
      }
    }
  | {
      type: 'renderVideo'
      accessTaskId?: string
      payload: {
        videoId: string
        language: string
        version: number
        mode: 'trim' | 'draft' | 'final'
      }
    }
  | {
      type: 'copyData'
      payload: {
        videoId: string
        language: string
        version?: number
        reuseVideo?: boolean
        isAutoTranslated?: boolean
        reuseRenderDefinition?: boolean
      }
    }
  | {
      type: 'checkIn' | 'checkOut' | 'forceCheckIn'
      payload: {
        videoId: string
      }
    }
  | {
      type: 'orderVideoProduction'
      payload: {
        videoId: string
        version: number
        language: string
      }
    }
  | {
      type: 'copyManuscriptIntoDescription'
      payload: {
        videoId: string
        language: string
        version?: number
      }
    }
  | {
      type: 'archive'
      payload: {
        videoId: string
      }
    }
  | {
      type: 'createLink'
      payload: {
        videoId: string
        trackId: string
      }
    }
  | {
      type: 'archiveLink'
      payload: {
        videoId: string
        linkId: string
      }
    }
  | {
      type: 'addVideoTag' | 'removeVideoTag'
      payload: {
        videoId: string
        version: number
        tagGroup: string
        tagValue: string
      }
    }
  | {
      type: 'addReviewer' | 'removeReviewer'
      payload: {
        videoId: string
        userEmail: string
      }
    }
  | {
      type: 'startTranscription'
      accessTaskId?: string
      payload: {
        videoId: string
        language: string
        transcribeId?: string
        version: number
      }
    }
  | {
      type: 'copyAutoTranscribeToRenderData'
      payload: {
        videoId: string
        language: string
        transcribeId: string
        version: number
      }
    }
  | {
      type: 'translateText'
      payload: {
        text: string | string[]
        sourceLang: string
        targetLang: string
      }
    }
  | {
      type: 'requestVideoTranslation'
      payload: {
        videoId: string
        languages: string[]
        version: number
      }
    }
  | {
      type: 'approveVideoTranslationRequest'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'processVideoTranslation'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'completeVideoTranslation'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'approveVideoTranslation'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'reviewVideoTranslation'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'requestVideoAccess'
      payload: {
        videoId: string
        message?: string
      }
    }
  | {
      type: 'rejectVideoAccessRequest'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'createVideoGenerationOrder'
      payload: {
        videoId: string
        version: number
        language: string
      }
    }
  | {
      type: 'approveGenerateVideoOrder'
      payload: {
        taskId: string
        deliveryDate: string
      }
    }
  | {
      type: 'completeVideoGenerationOrder'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'approveGeneratedVideo'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'rejectGeneratedVideo'
      payload: {
        taskId: string
      }
    }
  | {
      type: 'reSubmitGeneratedVideo'
      payload: {
        taskId: string
      }
    }

type RefetchFunc = () => Promise<ApolloQueryResult<any>>
export const videoCommandMutation = (refetchQueries: RefetchFunc[], timeout: number = 2000) => ({
  mutation: gql`
    mutation videoCommand($input: REST!) {
      res(input: $input) @rest(type: "Video", path: "/videoCommand", method: "PUT", endpoint: "command") {
        id
        translated
      }
    }
  `,
  onCompleted: () => {
    setTimeout(() => {
      refetchQueries.forEach(refetchQuerie => {
        refetchQuerie && refetchQuerie()
      })
    }, timeout)
  },
})

export const videoCommandVariables = (command: VideoCommand): any => {
  return {
    variables: {
      input: {
        type: command.type,
        payload: omit(
          command.payload,
          val => val === null || val === undefined /* || (typeof val === 'string' && val.trim() === '') */
        ),
        ...('accessTaskId' in command ? { accessTaskId: command.accessTaskId } : {}),
      },
    },
  }
}

export const VIDEO_COMMAND_MUTATION = gql`
  mutation videoCommand($input: REST!) {
    res(input: $input) @rest(type: "Video", path: "/videoCommand", method: "PUT", endpoint: "command") {
      id
      translated
    }
  }
`
