import TaskTypes from 'src/enums/TaskTypes'
import TaskCategories from 'src/enums/TaskCategories'

export const trackStatusToIntlMap = {
  canceled: 'canceled',
  completed: 'completed',
  inProgress: 'inProgress',
  notStarted: 'notStarted',
  overdue: 'overdue',
  planned: 'planned',
  skipped: 'skipped',
  requested: 'requested',
  rejected: 'rejected',
  processing: 'processing',
  review: 'review',
  reProcess: 'reProcess',
}

// TODO: Need to select better shade of these colors
export const trackStatusToColorMap = {
  canceled: '#800020',
  completed: 'green',
  inProgress: 'blue',
  notStarted: '#b48648',
  overdue: 'red',
  planned: 'pink',
  rejected: 'red',
  requested: 'blue',
  processing: '#07C64A',
  review: '#309350',
  reProcess: '#FF4242',
}

export const taskTypeToIntlMap = {
  [TaskTypes.WATCH_VIDEO]: 'watch-video',
  [TaskTypes.OPERATIONAL]: 'operation',
  [TaskTypes.GOVERNANCE]: 'governance',
}

export const taskCategoryToIntlMap = {
  [TaskCategories.MANDATORY]: 'mandatory',
  [TaskCategories.RECOMMENDED]: 'recommended',
  [TaskCategories.SHARED]: 'shared',
}
